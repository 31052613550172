












































import Vue from 'vue'
import BeneficiaryGroup from '../../../../../store/models/BeneficiaryGroup'

export default Vue.extend({
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'Nom du groupe',
          field: 'name',
          searchable: true
        },
        {
          label: 'Montant Perçu',
          field: 'totalReceived'
        },
        {
          label: 'Montant Escompté',
          field: 'totalDue'
        },
        {
          label: 'Statut',
          field: 'status'
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'left'
        }
      ]
    }
  },

  computed: {
    dispatchingId(): string {
      return this.$route.params.dispatchingId
    },

    groups(): BeneficiaryGroup[] {
      return BeneficiaryGroup.byDispatching(this.dispatchingId)
    }
  },

  created() {
    this.loading = true
    BeneficiaryGroup.api()
      .fetchByDispatching(this.dispatchingId)
      .then(() => {
        this.loading = false
      })
      .catch((error: Error) => {
        console.error(error)
        this.loading = false
      })
  },

  methods: {
    onSeeDetails(group: BeneficiaryGroup) {
      // TODO: Disbursments Details page
      this.$router.push({
        name: 'finances.refunds.details',
        params: { beneficiaryGroupId: group.id }
      })
    }
  }
})
