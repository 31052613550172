var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-box',[_c('v-box',{staticClass:"px-5 pt-4 pb-16 m-10 bg-white rounded",attrs:{"h":"full","shadow":"md"}},[_c('v-text',{staticClass:"mb-4",attrs:{"as":"p","weight":"light"}},[_vm._v(" Liste Des Remboursements Par Groupe ")]),_c('v-data-table',{staticClass:"mt-5",attrs:{"hide-add-action":"","columns":_vm.columns,"items":_vm.groups,"loading":_vm.loading},scopedSlots:_vm._u([{key:"totalReceived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalReceived))+" ")]}},{key:"totalDue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.totalDue))+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.totalDue === item.totalPaid)?_c('span',[_vm._v(" Atteint ")]):_c('span',[_vm._v("Non atteint")])]}},{key:"_actions",fn:function(ref){
var refund = ref.item;
return [_c('v-button',{staticClass:"text-base normal-case",attrs:{"xsmall":"","type":"button","color":"blue-300"},on:{"click":function($event){return _vm.onSeeDetails(refund)}}},[_vm._v("Voir détails")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }